<template>
  <div :class="['toastification', variant]">
    <div class="toast__container">
        <div class="col-icon">
            <IconSuccess v-if="variant == 'success'"/>
            <IconError v-else-if="variant == 'danger'"/>
            <IconAlertOutline v-else-if="icon === 'AlertOutline'"/>
            <IconAlert v-else-if="variant =='alert'"/>
            <IconInfo v-else/>
        </div>

        <div class="toast__content">
            <div class="toast__text-area">
                <span class="toast__title" v-if="title">
                    {{ title }}
                </span>
                <span class="toast__text" v-if="text">
                    {{ text }}
                </span>
            </div>
        </div>

        <div class="toast__close-button-c">
            <IconClose @click="$emit('close-toast')"/>
        </div>
    </div>
  </div>
</template>

<script>
import IconSuccess from '../../../assets/images/pages/check-circle-icon.svg'
import IconError from '../../../assets/images/pages/cancel-circle-icon.svg'
import IconInfo from '../../../assets/images/pages/info-circle-icon.svg'
import IconAlert from '../../../assets/images/pages/alert-circle-icon.svg'
import IconClose from '../../../assets/images/new-icons/close-toast.svg'
import IconAlertOutline from '../../../assets/images/pages/alert-toastification-icon.svg';

export default {
  components: {
    IconSuccess,
    IconError,
    IconInfo,
    IconAlert,
    IconClose,
    IconAlertOutline
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
</style>

<style lang="scss" scoped>


.toastification{
  width: 380px;
  padding: 16px;
  margin: 0 !important;

  &.success{
    background-color: #4CAF50;
  }
  &.danger{
    background-color: #F44336;
  }
  &.alert{
    background-color: #FF9800;
  }
  &.info{
    background-color: #2196F3;
  }
  .toast__container{
      display: flex;

        .col-icon {
            margin-top: 5px;
        }

        .toast__close-button-c {
            cursor: pointer;

            svg {
                width: 24px;
                height: 24px;
            }
        }

      .toast__content {
          width: 100%;
          .toast__text-area {
            margin-left: 4px;

            display: flex;
            flex-direction: column;
            .toast__title {
                color: #ffff;
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
            }

            .toast__text {
                color: #ffff;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .toast__close-button-c {
        margin-left: 16px;
    }
  }
}

@media (max-width:485px) {
    .toastification{
        width: 288px !important;
    }
}
</style>

<style lang="scss" >
.Vue-Toastification__toast {
    width: auto !important;
}

.Vue-Toastification__container {
    display: flex;
    align-items: end;
    gap: 16px;
    top: 16px !important;
    right: 0 !important;
}

.Vue-Toastification__toast{
    min-width: 1px;
    padding: 0;
    margin: 0;
    right: 16px !important;
}

.Vue-Toastification__toast-component-body {
    min-width: 1px !important;
    padding: 0;
    margin: 0;

}

 @media (max-width: 480px) {
    .Vue-Toastification__container{
        align-items: end;
    }

    .Vue-Toastification__toast{
        padding: 0 !important;
        width: 288px !important;
        min-width: 1px;
    }
 }
</style>

